import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/helpers/auth.guard';
import { CustomPreloadingStrategy } from './services/custom-preloading-strategy.service';
import { GovBrComponent } from './govbr';
import { LogoutComponent } from './autenticacao/logout/logout.component';
import { RoleNotFoundComponent } from './core/components/role-not-found/role-not-found.component';
import { PesquisaSatisfacaoModule } from './pesquisa-satisfacao/pesquisa-satisfacao.module';

const routes: Routes = [
	// {
	// 	path: '',
	// 	pathMatch: 'full',
	// 	redirectTo: 'auth',
	// },
	// {
	// 	path: 'auth',
	// 	loadChildren: () =>
	// 		import('./autenticacao/autenticacao.module').then((m) => m.AutenticacaoModule),
	// 	data: { titulo: 'Acesso do Cidadão' },
	// },
	// {
	// 	path: 'solicitacoes',
	// 	loadChildren: () =>
	// 		import('./solicitacoes/solicitacoes.module').then((m) => m.SolicitacoesModule),
	// 	canActivate: [AuthGuard],
	// 	data: { titulo: 'Minhas Solicitações', preload: true },
	// },
	// {
	// 	path: 'formularios',
	// 	loadChildren: () =>
	// 		import('./formularios/formularios.module').then((m) => m.FormulariosModule),
	// 	canActivate: [AuthGuard],
	// 	data: { titulo: 'Solicitação de Serviço', preload: true },
	// },
	// {
	// 	path: 'infos-usuario',
	// 	loadChildren: () =>
	// 		import('./infos-usuario/infos-usuario.module').then((m) => m.InfosUsuarioModule),
	// 	canActivate: [AuthGuard],
	// 	data: { titulo: 'Meus Dados', preload: true },
	// },
	// {
	// 	path: 'apps',
	// 	loadChildren: () =>
	// 		import('./microfrontends/microfrontends.module').then((m) => m.MicrofrontendsModule),
	// 	canActivate: [AuthGuard]
	// },
	// {
	// 	path: 'usuario-sem-permissao',
	// 	component: RoleNotFoundComponent,
	// },
	// {
	// 	path: 'login-govbr',
	// 	component: GovBrComponent,
	// 	data: { preload: true },
	// },
	// {
	// 	path: 'nivel-confiabilidade-govbr-requerida',
	// 	loadChildren: () =>
	// 		import('./nivel-confiabilidade-govbr/nivel-confiabilidade-govbr-requerida.module').then((m) => m.NivelConfiabilidadeGovBrModule),
	// 	canActivate: [AuthGuard],
	// 	data: { manterTituloPagina: true, preload: true },
	// },
	// {
	// 	path: 'requer-atualizacao-dados',
	// 	loadChildren: () =>
	// 		import('./requer-atualizacao-dados/requer-atualizacao-dados.module').then((m) => m.RequerAtualizacaoModule),
	// 	data: { manterTituloPagina: true, preload: true },
	// },
	{
		path: 'pesquisa-satisfacao',
		loadChildren: () =>
			import('./pesquisa-satisfacao/pesquisa-satisfacao.module').then((m) => m.PesquisaSatisfacaoModule),
		data: { titulo: "Avaliação de Serviço",preload: true },
	},
	// {
	// 	path: 'logout',
	// 	component: LogoutComponent,
	// },
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: CustomPreloadingStrategy,
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
