import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { StatusMessage } from 'src/app/models/status-message.model';

@Component({
    selector: 'app-status-message',
    templateUrl: './status-message.component.html',
    styleUrls: ['./status-message.component.scss']
})
export class StatusMessageComponent {

    public statusMessage: StatusMessage;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: StatusMessage) {
        this.statusMessage = data;
    }
}