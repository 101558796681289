import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  // Sentry.init({
  //   dsn: "https://9da96ab4d25740a6bb1bf770ffca99ef@o1114821.ingest.sentry.io/6146280" ,
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: [
  //         'localhost',
  //         'sc-digital-frontend-stage-scdigital-stage.apps.okd.ciasc.sc.gov.br',
  //         'api.scdigital.sc.gov.br'
  //       ],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     })
  //   ],
  //   tracesSampleRate: 1.0,
  //   environment: 'prod',
  //   release: 'sc-digital-frontend@' + version
  // });

  // Sentry.configureScope((scope) => {
  //   scope.setUser({
  //     'username': localStorage.getItem('usuarioLogado') || 'anônimo'
  //   });
  // });

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
