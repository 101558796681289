import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenSCDigital } from 'sc-digital-shared-types/types';
import { environment } from '../../../src/environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class IdentityServerService {

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }



    getTokenIdentityServer(code: string): Observable<any> {
        const code_verifier = localStorage.getItem('app-code-verifier');

        localStorage.removeItem('app-code-verifier');
        const body = new HttpParams()
            .set('grant_type', 'authorization_code')
            .set('client_id', environment.clientIdIdentityServer)
            .set('redirect_uri', environment.urlIdentityServerRetorno)
            .set('code', code)
            .set('code_verifier', code_verifier);

        return this.http.post<any>(
            `${environment.urlIdentityServer}/oauth2/token`,
            body.toString(),
            { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'}) }
        );
    }

    loginScDigital(tokenCompleto: string) {
        return this.http.post<any>(
            `${environment.apiAuthenticateUrl}/auth/login`,
            { tokenCompleto },
            { headers: new HttpHeaders({'Content-Type': 'application/json'}) }
        ).pipe(tap((tokenSCDigital: TokenSCDigital) => {
            this.userService.setToken(tokenSCDigital);
        }));
    }
}
