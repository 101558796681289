import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { TokenSCDigital, EventoAutenticacaoSCDigital } from 'sc-digital-shared-types/types';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Usuario } from '../models/usuario.model';
import { Observable } from 'rxjs';
import { RequisicoesService } from './requisicoes.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root'})
export class UserService {

    constructor(
        private tokenService: TokenService,
        private httpClient: HttpClient,
        private requisicoesService: RequisicoesService,
        private cookieService: CookieService
    ) { }

    setToken(token: TokenSCDigital) {
        this.tokenService.setToken(token);
        this.requisicoesService.enviaEventoSCDigital(new EventoAutenticacaoSCDigital({
            usuario: token.nome,
            containerVisivel: true
        }));
    }

    logout() {
        this.tokenService.removeToken();

        this.requisicoesService.enviaEventoSCDigital(new EventoAutenticacaoSCDigital({
            usuario: null,
            containerVisivel: false
        }));
    }

    isLogged() {
        return this.tokenService.hasToken();
    }

    setLoggedUser(idUsuario: number) {
        this.cookieService.set('loggedUser', idUsuario.toString());
    }

    getIdUsuarioLogado() {
        if (this.cookieService.get('loggedUser')) {
            return +this.cookieService.get('loggedUser');
        } else {
            return +localStorage.getItem('loggedUser');
        }
    }

    getNomeUsuarioLogado() {
        if (this.cookieService.get('usuarioLogado') != '') {
            return this.cookieService.get('usuarioLogado');
        } else {
            return localStorage.getItem('usuarioLogado');
        }
    }

    getDadosUsuarioLogado(): Observable<Usuario> {
        return this.httpClient.get<Usuario>(`${environment.apiUrl}/usuario/`);
    }

    getInfosUsuario(): Usuario {
        let token = this.tokenService.getDecodedToken();
		return  <Usuario>token.infosUsuario;
    }

    alteraSenhaUsuario(senhaAtual: string, novaSenha: string): Observable<TokenSCDigital> {
        return this.httpClient.post<TokenSCDigital>(`${environment.apiUrl}/auth/trocar-senha`, { senha: senhaAtual, novaSenha: novaSenha });
    }

    alteraEmailUsuario(novoEmail: string, senha: string): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/usuario/requisitar-alteracao-email?email=${novoEmail}&senha=${senha}`, null);
    }

    alteraDadosUsuario(usuario: Usuario): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/usuario/atualizar-dados`, usuario);
    }

    confirmacaoAlteracaoEmailUsuario(token: string): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/usuario/confirmar-alteracao-email?token=${token}`, null);
    }
}