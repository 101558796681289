export const environment = {
	production: true,
	homolog: false,
	apiUrl: 'https://sc-digital-api-prod.prod.okd4.ciasc.sc.gov.br',
	apiAuthenticateUrl: 'https://sc-digital-auth-api-prod.prod.okd4.ciasc.sc.gov.br',
	apiFormsUrl: 'https://api.forms.scdigital.sc.gov.br',
	cartaWebUrl: 'https://www.sc.gov.br/servicos',
	apiLogradourosUrl: 'https://apim.ciasc.sc.gov.br:8243/logradouros',
	agendamentoMicrofrontendUrl: 'https://scdigital.sc.gov.br/to/agendamento/',
	agendamentoApiUrl: 'https://backend-agendamento.iprev.sc.gov.br/agendamento-api/',
	loginLogradouros: 'scdigital-usuario',
	senhaLogradouros: '46A?c3z3_!W6Yj',

	urlIdentityServer: 'https://acesso.ciasc.sc.gov.br',
	urlIdentityServerRetorno: 'https://scdigital.sc.gov.br/login-govbr',
	urlIdentityServerRetornoLogout: 'https://scdigital.sc.gov.br/logout',
	clientIdIdentityServer: '12Rfby4aVmturwa1wnbQ0TwRWEka',
	redirectLogoutUri: 'https://sso.acesso.gov.br/logout?post_logout_redirect_uri=',
	hereAppId: 'NknqnFwJwpZcCX3ikBBg',
	hereKey: 'tmdBR6UHRP9aY_q6BQQ3ZoRWwBI-yw9l52-a4wheglI',
	apiKeyLogradouros: 'eyJ4NXQiOiJZMkV6WVRFNU4yRTBZelZrWkRKaVl6WTJNR1U0T0dWaFlXRm1aV1JsWkRJeVlUUmhNalJrWlE9PSIsImtpZCI6ImdhdGV3YXlfY2VydGlmaWNhdGVfYWxpYXMiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwMTIxNzA0MTA2MEBjYXJib24uc3VwZXIiLCJhcHBsaWNhdGlvbiI6eyJvd25lciI6IjAxMjE3MDQxMDYwIiwidGllclF1b3RhVHlwZSI6bnVsbCwidGllciI6IlVubGltaXRlZCIsIm5hbWUiOiJTQyBEaWdpdGFsIiwiaWQiOjE4LCJ1dWlkIjoiZDVhMThmMWMtMjBhNC00MjZlLWIwMDYtNTAwNzdkYTFlZmYwIn0sImlzcyI6Imh0dHBzOlwvXC9hcGltLmNpYXNjLnNjLmdvdi5icjo0NDNcL29hdXRoMlwvdG9rZW4iLCJ0aWVySW5mbyI6eyJVbmxpbWl0ZWQiOnsidGllclF1b3RhVHlwZSI6InJlcXVlc3RDb3VudCIsInN0b3BPblF1b3RhUmVhY2giOnRydWUsInNwaWtlQXJyZXN0TGltaXQiOjAsInNwaWtlQXJyZXN0VW5pdCI6bnVsbH19LCJrZXl0eXBlIjoiUFJPRFVDVElPTiIsInN1YnNjcmliZWRBUElzIjpbeyJzdWJzY3JpYmVyVGVuYW50RG9tYWluIjoiY2FyYm9uLnN1cGVyIiwibmFtZSI6IkdlcmFyQmlsaGV0ZVJlc3QiLCJjb250ZXh0IjoiXC9sb2dyYWRvdXJvc1wvR2VyYXJCaWxoZXRlUmVzdFwvdjIiLCJwdWJsaXNoZXIiOiJhZG1pbiIsInZlcnNpb24iOiJ2MiIsInN1YnNjcmlwdGlvblRpZXIiOiJVbmxpbWl0ZWQifV0sImlhdCI6MTYwMTY3MzY0OSwianRpIjoiZjIxNTVjYTItYmE0My00MGExLWI1YTctOTc5NGQzNGFlMzFiIn0=.oBo5cRT2A_yKlbP9H7hmC7obLKdqtcjcwo3eoXOrAvQImsa3cEvX_RmTnRoK6biNFIq35RWzIsM-kAs7SSu0lHmzuVP35xti9O31Z0pOKmhsd12E1iujgI3ZVs7ij5LNaFt7TD7fCVI1E9dWJWXns68w0iMZ755ZeT-nyIzTkj8hHsiU95Yb-lROXB1YxPsQcXePeF29UhYsymBGtae9M6mVKFsbgJTf-A_70qq2uMlGL5Eu668euyGDPfCk4m4IjlZW2bvACQYeNXqXObNDR7JK5gXpUF3D40zTau2BDY8x5wgja0A9gcewuWWLLv740xUURNAN7O2f_Bj9etM8Uw==',
	urlGovBrAlteracaoCadastro: 'https://contas.acesso.gov.br/alteracao_cadastro',
	urlGovBrConfiabilidades: 'https://confiabilidades.acesso.gov.br',
	urlPortal: 'https://www.sc.gov.br',
};
