import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusResposta } from 'src/app/enums';
import { StatusMessage } from 'src/app/models/status-message.model';
import { StatusMessageComponent } from '../../core/components/status-message/status-message.component';

@Injectable({ providedIn: 'root' })
export class StatusMessageService {

    public classeStatus = 'error-message';

    constructor(private snackBar: MatSnackBar) {}

    public openStatusMessage(statusMessage: StatusMessage) {

        if (statusMessage.statusResposta === StatusResposta.SUCESSO) {
            this.classeStatus = 'success-message';
        }

        this.snackBar.openFromComponent(StatusMessageComponent, {
            duration: 6000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            data: statusMessage,
            panelClass: [this.classeStatus]
        });
    }
}
