export class NiveConfiabilidadeGovBrEnum {
	static BRONZE = {
		id: 1,
		descricao: 'Nível Bronze'
	}
	static PRATA = {
		id: 2,
		descricao: 'Nível Prata'
	}
	static OURO = {
		id: 3,
		descricao: 'Nível Ouro'
	}

	static getFromId(id) {
		switch(id) {
			case 1: {
				return NiveConfiabilidadeGovBrEnum.BRONZE;
			}
			case 2: {
				return NiveConfiabilidadeGovBrEnum.PRATA;
			}
			case 3: {
				return NiveConfiabilidadeGovBrEnum.OURO;
			}
		}
	}
}

export enum SistemaIntegracaoEnum {
	SC_DIGITAL = 1
}

export enum Mascara {
	Cpf = '000.000.000-00',
	Cnpj = '00.000.000/0000-00',
	Telefone10Dig = '(00) 0000-00009',
	Telefone11Dig = '(00) 00000-0000',
	PisNis = '00.00000.000-0',
}

export enum StatusResposta {
	ERRO = 1,
	SUCESSO = 2,
}

export enum CodigoErroAPI {
	EMAIL_NAO_CONFIRMADO = 1,
}

export enum TipoGrupo {
	FORM = 1,
	METADADO = 2,
}

export enum TamanhoMaximoUpload {
	GERAL = 52428800, // 52.428.800 = 50mb, 15728640 = 15mb
}

export enum FormatoValidoUpload {
	PDF = 'application/pdf',
	DWG = 'image/vnd.dwg',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	XLS = 'application/vnd.ms-excel',
}

export enum DeclaracaoLegal {
	INFORMACOES_VERDADEIRAS = 'Declaro, para os devidos fins e efeitos legais, serem pessoais e verdadeiras as informações inseridas neste requerimento, sobre as quais assumo todas as responsabilidades, sob pena de incorrer nas sanções previstas nos artigos 299 e 307 do Código Penal (falsidade ideológica e falsa identidade).',
	COPIAS_DIGITALIZADAS = 'Declaro estar ciente de que as cópias digitalizadas não possuem valor de original e que a pesquisa será realizada apenas nos instrumentos de pesquisa (índices, inventários, catálogos etc.), não compreendendo consultas exaustivas nos fundos documentais e coleções.',
	ARQUIVAMENTO_CASO_INFORMACOES_DESACORDO = 'Fico ciente de que o processo será arquivado caso as informações apresentadas estejam em desacordo com o solicitado ou com a legislação vigente.',
}

export enum StatusSolicitacao {
	ABERTA = 0,
	PENDENCIA = 2,
	FINALIZADA = 4,
}

export enum EstadoCivil {
	SOLTEIRO = 'Solteiro',
	CASADO = 'Casado',
	DIVORCIADO = 'Divorciado',
	SEPARADO = 'Separado',
	VIUVO = 'Viúvo',
}

export enum Solicitante {
	INTERESSADO = 'Interessado',
	REPRESENTANTE = 'Representante',
	SOLICITANTE = 'Solicitante',
	PROPRIETARIO = 'Proprietário',
	REPRESENTANTE_CPF = 'Representante de Pessoa Física',
	REPRESENTANTE_CNPJ = 'Representante de Pessoa Jurídica',
	REPRESENTANTE_ORGAO = 'Representante de Orgão',
}

export enum ExtensaoArquivo {
	DOC = 'doc',
	DOCX = 'docx',
	DWG = 'dwg',
	GIF = 'gif',
	HTML = 'html',
	JPEG = 'jpeg',
	JPG = 'jpg',
	ODT = 'odt',
	PDF = 'pdf',
	PNG = 'png',
	TXT = 'txt',
	XLS = 'xls',
	XLSX = 'xlsx',
	XML = 'xml',
	ZIP = 'zip',
}

export class ExtensaoGrupo {
	labelFormato: string;
	extensoesValidas: string[];
	constructor(labelFormato: string, extensoesValidas: string[]) {
		this.labelFormato = labelFormato;
		this.extensoesValidas = extensoesValidas;
	}
}

export class ExtensaoGrupoEnum {
	static IMAGENS: ExtensaoGrupo = new ExtensaoGrupo('Imagem', [
		ExtensaoArquivo.GIF,
		ExtensaoArquivo.JPEG,
		ExtensaoArquivo.JPG,
		ExtensaoArquivo.PNG,
	]);
	static IMAGENS_PDF: ExtensaoGrupo = new ExtensaoGrupo(
		'Imagem ou PDF',
		ExtensaoGrupoEnum.IMAGENS.extensoesValidas.concat([ExtensaoArquivo.PDF])
	);
	static ZIP: ExtensaoGrupo = new ExtensaoGrupo('Somente .zip', [ExtensaoArquivo.ZIP]);
	static PDF: ExtensaoGrupo = new ExtensaoGrupo('PDF', [ExtensaoArquivo.PDF]);
	static PDF_ZIP: ExtensaoGrupo = new ExtensaoGrupo('PDF ou ZIP', ExtensaoGrupoEnum.IMAGENS.extensoesValidas.concat([ExtensaoArquivo.PDF, ExtensaoArquivo.ZIP]));
}

export class TipoSolicitacao {
	id: number;
	//nome: string;
	modulo: Modulo;

	constructor(id: number, modulo: Modulo) {
		this.id = id;
		this.modulo = modulo;
	}
}

export class Modulo {
	id: number;
	nome: string;
	maxFileSize: number;
	maxFileSizeMB: number;
	maxFileNameLength: number;
	extensoes: string[];

	constructor(id: number, nome: string, maxFileSize: number, maxFileNameLength : number, extensoes: string[]) {
		this.id = id;
		this.nome = nome;
		this.maxFileSize = maxFileSize;
		this.extensoes = extensoes;
		this.maxFileNameLength = maxFileNameLength == null ? 255 : maxFileNameLength;
		this.setDefaults();
	}

	private setDefaults() {
		this.maxFileSize = this.maxFileSize == null ? TamanhoMaximoUpload.GERAL : this.maxFileSize;
		this.maxFileSizeMB = +(this.maxFileSize / 1024 / 1024).toFixed(2);
	}
}

export class ModuloEnum {
	static SGPE: Modulo = new Modulo(1, 'SGPE', 15728640, 150, [
		'doc',
		'docx',
		'dwg',
		'gif',
		'html',
		'jpeg',
		'jpg',
		'odt',
		'pdf',
		'png',
		'txt',
		'xls',
		'xlsx',
		'xml',
		'zip',
	]);
	static COVID19: Modulo = new Modulo(2, 'COVID-19', null, null,null);
	static INTEGRADOR: Modulo = new Modulo(3, 'Integrador', null, null, null);
	static SGPE_SYNC: Modulo = new Modulo(4, 'SGPE SYNC', 15728640, 150,[
		'doc',
		'docx',
		'dwg',
		'gif',
		'html',
		'jpeg',
		'jpg',
		'odt',
		'pdf',
		'png',
		'txt',
		'xls',
		'xlsx',
		'xml',
		'zip',
	]);
}

export class TipoSolicitacaoEnum {
	static SOLICITACAO_SC_DIGITAL: TipoSolicitacao = new TipoSolicitacao(1, ModuloEnum.SGPE);
	static SOLICITACAO_SGPE_RESERVA_TEATRO: TipoSolicitacao = new TipoSolicitacao(
		2,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_CJM: TipoSolicitacao = new TipoSolicitacao(3, ModuloEnum.SGPE);
	static SOLICITACAO_SGPE_ERE: TipoSolicitacao = new TipoSolicitacao(4, ModuloEnum.SGPE);
	static SOLICITACAO_SGPE_CRIADOR_PASSAROS: TipoSolicitacao = new TipoSolicitacao(
		5,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_PESQ_DIST_ACERVO_ARQ_PUBLICO: TipoSolicitacao = new TipoSolicitacao(
		6,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_RETIFICACAO_BOAT: TipoSolicitacao = new TipoSolicitacao(
		7,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_PROCESSO_ESTADO: TipoSolicitacao = new TipoSolicitacao(
		8,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_COVID19_NOTIFICACAO_COVID19: TipoSolicitacao = new TipoSolicitacao(
		9,
		ModuloEnum.COVID19
	);
	static SOLICITACAO_SGPE_RECADASTRAMENTO_CRIADOR_PASSAROS: TipoSolicitacao = new TipoSolicitacao(
		10,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_HOMOLOGACAO_CRIADOR_PASSAROS: TipoSolicitacao = new TipoSolicitacao(
		11,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_CERTIDAO_OU_DECLARACAO_DE_TEMPO_DE_CONTRIBUICAO: TipoSolicitacao =
		new TipoSolicitacao(12, ModuloEnum.SGPE);
	static SOLICITACAO_SGPE_DECLARACAO_DENOMINACAO_BENS_PUBLICOS: TipoSolicitacao =
		new TipoSolicitacao(13, ModuloEnum.SGPE);
	static SOLICITACAO_PENSAO_POR_EPIDERMOLISE_BOLHOSA: TipoSolicitacao = new TipoSolicitacao(
		14,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_PENSAO_POR_DEFICIENCIA_INTELECTUAL_GRAVE_OU_PROFUNDA: TipoSolicitacao =
		new TipoSolicitacao(15, ModuloEnum.SGPE);
	static SOLICITACAO_PENSAO_POR_HANSENIASE_EGRESSO_DO_HOSPITAL_SANTA_TEREZA: TipoSolicitacao =
		new TipoSolicitacao(16, ModuloEnum.SGPE);
	static SOLICITACAO_SGPE_OFERTA_GARANTIA_ANTECIPADA: TipoSolicitacao = new TipoSolicitacao(
		17,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_COVID19_NOTIFICACAO_AFASTAMENTO: TipoSolicitacao = new TipoSolicitacao(
		18,
		ModuloEnum.INTEGRADOR
	);
	static SOLICITACAO_DEFESA_AUTUACAO_MULTAS_ESTADUAIS: TipoSolicitacao = new TipoSolicitacao(
		19,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_DEFESA_AUTUACAO_MULTAS_ESTADUAIS_RODOVIAS: TipoSolicitacao =
		new TipoSolicitacao(20, ModuloEnum.SGPE);
	static SOLICITACAO_DIREITOS_DADOS_PESSOAIS_LGDP_CIASC: TipoSolicitacao = new TipoSolicitacao(
		21,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_TORNEIO_CANTO_PASSARO: TipoSolicitacao = new TipoSolicitacao(
		22,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_DEFESA_AUTUACAO_MULTAS_ESTADUAIS_RODOVIAS_CETRAN: TipoSolicitacao =
		new TipoSolicitacao(23, ModuloEnum.SGPE);
	static ALTERACAO_SEXAGEM_PASSERIFORMES_SISPASS_IMA: TipoSolicitacao = new TipoSolicitacao(
		24,
		ModuloEnum.SGPE
	);
	static CANCELAMENTO_LICENCA_CRIADOR_AMADORISTA_DE_PASSAROS: TipoSolicitacao =
		new TipoSolicitacao(25, ModuloEnum.SGPE);
	static SOLICITAR_DEVOLUCAO_DE_ANILHA_ATRAVES_DE_BOLETIM_DE_OCORRENCIA: TipoSolicitacao =
		new TipoSolicitacao(26, ModuloEnum.SGPE);
	static INCLUSAO_DE_PASSERIFORME_COM_NOTA_FISCAL: TipoSolicitacao = new TipoSolicitacao(
		27,
		ModuloEnum.SGPE
	);
	static ANTECIPACAO_DE_RECEBIMENTO_DE_PRECATORIOS: TipoSolicitacao = new TipoSolicitacao(
		30,
		ModuloEnum.SGPE
	);
	static EQUIVALENCIA_ESTUDOS_REALIZADOS_EXTERIOR: TipoSolicitacao = new TipoSolicitacao(
		28,
		ModuloEnum.SGPE
	);
	static SOLICITAR_DOCUMENTOS_DAS_ESCOLAS_DESATIVADAS: TipoSolicitacao = new TipoSolicitacao(
		29,
		ModuloEnum.SGPE
	);
	static SOLICITAR_ATESTADO_REGULARIDADE_DE_ESTUDOS: TipoSolicitacao = new TipoSolicitacao(
		31,
		ModuloEnum.SGPE
	);
	static SED_DECLARACAO_PARCIAL_PROEFICIENCIA: TipoSolicitacao = new TipoSolicitacao(
		32,
		ModuloEnum.SGPE
	);
	static SOLICITACAO_SGPE_PESQUISA_ESPECIES_AMEACADAS_E_EXOTICAS: TipoSolicitacao =
		new TipoSolicitacao(33, ModuloEnum.SGPE);
	static DENUNCIA_PROCON: TipoSolicitacao = new TipoSolicitacao(34, ModuloEnum.SGPE);
	static REGISTRO_E_RENOVACAO_DE_REGISTRO_DE_ASSOCIACAO_DE_CRIADORES: TipoSolicitacao =
		new TipoSolicitacao(35, ModuloEnum.SGPE);
	static SOLICITACAO_ANUENCIA_PROCESSO_EXTRAJUDICIAL: TipoSolicitacao = new TipoSolicitacao(
		36,
		ModuloEnum.SGPE
	);
	static RETORNO_PASSEIRIFORME_PLANTEL: TipoSolicitacao = new TipoSolicitacao(
		37,
		ModuloEnum.SGPE
	);
	static DADOS_GEOINFORMACAO: TipoSolicitacao = new TipoSolicitacao(38, ModuloEnum.SGPE);
	static INSCREVER_NO_PIC: TipoSolicitacao = new TipoSolicitacao(39, ModuloEnum.SGPE);
	static DETRAN_RENOVACAO_ALVARA_CFC: TipoSolicitacao = new TipoSolicitacao(40, ModuloEnum.SGPE);
	static AUXILIO_EMERGENCIAL_ESTADUAL: TipoSolicitacao = new TipoSolicitacao(
		41,
		ModuloEnum.INTEGRADOR
	);
	static SOLICITAR_BENEFICIO_PROGRAMA_PASSE_LIVRE_FERRY_BOART_ITAJAI_NAVEGANTES: TipoSolicitacao =
		new TipoSolicitacao(42, ModuloEnum.SGPE);
	static SOLICITAR_ATENDIMENTO_DOS_TITULARES_DE_DADOS_PESSOAIS_NO_AMBITO_DO_ESTADO: TipoSolicitacao =
		new TipoSolicitacao(43, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ANUAL_DE_ALVARA_PSICOLOGO_DETRAN: TipoSolicitacao =
		new TipoSolicitacao(44, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ANUAL_DE_ALVARA_MEDICO_DETRAN: TipoSolicitacao =
		new TipoSolicitacao(45, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ANUAL_ALVARA_ECV: TipoSolicitacao =
		new TipoSolicitacao(46, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ANUAL_ALVARA_DESPACHANTE_TRANSITO: TipoSolicitacao =
		new TipoSolicitacao(47, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ANUAL_ALVARA_REMARCADORA_CHASSI: TipoSolicitacao =
		new TipoSolicitacao(48, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ANUAL_ALVARA_CLINICA_PSICOLOGICA: TipoSolicitacao =
		new TipoSolicitacao(49, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ANUAL_ALVARA_CLINICA_MEDICA: TipoSolicitacao =
		new TipoSolicitacao(50, ModuloEnum.SGPE);
	static SOLICITAR_RENOVACAO_ALLVARA_EMPRESA_ESTAMPADORAS_PIV: TipoSolicitacao =
		new TipoSolicitacao(55, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_ESTAMPADORAS_PIV: TipoSolicitacao =
		new TipoSolicitacao(56, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_PERITO_MEDICO_PF: TipoSolicitacao =
		new TipoSolicitacao(57, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_PERITO_PSICOLOGO_PF: TipoSolicitacao =
		new TipoSolicitacao(58, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_CLINICA_MEDICA_PJ: TipoSolicitacao =
		new TipoSolicitacao(59, ModuloEnum.SGPE);
	static SOLICITAR_PENSAO_POR_MORTE_IPREV: TipoSolicitacao =
		new TipoSolicitacao(64, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_CLINICA_PSICOLOGICA_PJ: TipoSolicitacao =
		new TipoSolicitacao(60, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_EMPRESA_CERTIFICACAO_VEICULAR: TipoSolicitacao =
		new TipoSolicitacao(61, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_CFC_ETAPA_1: TipoSolicitacao =
		new TipoSolicitacao(62, ModuloEnum.SGPE);
	static SOLICITAR_CREDENCIAMENTO_CFC_ETAPA_2: TipoSolicitacao =
		new TipoSolicitacao(63, ModuloEnum.SGPE);
	static SOLICITAR_TROCA_MARCA_ITEM_ATA_REGISTRO_PRECO: TipoSolicitacao =
		new TipoSolicitacao(65, ModuloEnum.SGPE);
	static SOLICITAR_REEQUILIBRIO_FINANCEIRO_ATA_REGISTRO_PRECO: TipoSolicitacao =
		new TipoSolicitacao(66, ModuloEnum.SGPE);
	static CREDENCIAMENTO_DESPACHANTE_TRANSITO: TipoSolicitacao =
		new TipoSolicitacao(67, ModuloEnum.SGPE);
	static CREDENCIAMENTO_REMARCADORA_CHASSI: TipoSolicitacao = new TipoSolicitacao(68, ModuloEnum.SGPE);
	static SOLICITAR_REVISAO_PENSAO_IPREV: TipoSolicitacao = new TipoSolicitacao(69, ModuloEnum.SGPE);
	static SOLICITAR_REVALIDACAO_DIPLOMA: TipoSolicitacao = new TipoSolicitacao(70, ModuloEnum.SGPE);
	static SOLICITAR_MATRICULA_ATIVIDADE_PARADESPORTIVAS: TipoSolicitacao = new TipoSolicitacao(71, ModuloEnum.SGPE);
	static SOLICITAR_ADAPTACAO_PROTESE_OCULAR: TipoSolicitacao = new TipoSolicitacao(935, ModuloEnum.SGPE);
	static CADASTRAR_PROCESSOS_DE_FATURAMENTO_DE_PUBLICIDADE: TipoSolicitacao = new TipoSolicitacao(72, ModuloEnum.SGPE);
	static SOLICITAR_REABILITACAO_VISUAL: TipoSolicitacao = new TipoSolicitacao(451, ModuloEnum.SGPE);
}

export class NomeMetadado {
	static INTERESSADOS: string = 'interessados';
	static OUTROS: string = 'outros';
	static SGPE: string = 'SGPE';
}

export const UF = [
	'',
	'AC',
	'AL',
	'AM',
	'AP',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MG',
	'MS',
	'MT',
	'PA',
	'PB',
	'PE',
	'PI',
	'PR',
	'RJ',
	'RN',
	'RO',
	'RR',
	'RS',
	'SC',
	'SE',
	'SP',
	'TO',
];