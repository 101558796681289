import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { StatusMessage } from '../models/status-message.model';

@Injectable({ providedIn: 'root' })
export class LayoutService {

    private carregando: EventEmitter<boolean> = new EventEmitter();
    private statusMessage: EventEmitter<StatusMessage> = new EventEmitter();
    public tamanhoFonteHtml: number;
    public janelaLogin: boolean = false;

    private tituloPagina: BehaviorSubject<string>;
    public tituloPagina$: Observable<string>;

    private atualizaSolicitacoes: BehaviorSubject<boolean>;
    public atualizaSolicitacoes$: Observable<boolean>;

    constructor(
        private titleService: Title,
    ) {
        this.tituloPagina = new BehaviorSubject('SC Digital');
        this.tituloPagina$ = this.tituloPagina.asObservable();

        this.atualizaSolicitacoes = new BehaviorSubject(false);
        this.atualizaSolicitacoes$ = this.atualizaSolicitacoes.asObservable();
    }

    public setTamanhoFonte(tamanho: number) {
        this.tamanhoFonteHtml = tamanho;
        this.atualizarTamanhoFonte();
    }

    private atualizarTamanhoFonte(): void {
        const html = document.body.parentElement as HTMLHtmlElement;
        html.style.fontSize = this.tamanhoFonteHtml + 'px';
    }

    scrollIntoViewByElement(element: HTMLElement) {
        element.scrollIntoView({ behavior: 'smooth' });

        if (element.classList[0] == 'file-upload' ) {
            (element.previousElementSibling as HTMLElement).focus();
        } else if (element.classList[0] == 'mat-checkbox') {
            (element.parentElement as HTMLElement).focus();
        } else {
            element.focus();
        }
    }

    carregandoEvent(): Observable<boolean> {
        return this.carregando;
    }

    carregandoEmit(carregando: boolean): void {
        this.carregando.emit(carregando);
    }

    statusMessageEvent(): Observable<StatusMessage> {
        return this.statusMessage;
    }

    statusMessageEmit(status: StatusMessage): void {
        this.statusMessage.emit(status);
    }

    setTituloPagina(titulo: string) {
        this.titleService.setTitle(titulo);
        this.tituloPagina.next(titulo);
    }

    setAtualizaSolicitacoes(atualizaSolicitacoes: boolean) {
        this.atualizaSolicitacoes.next(atualizaSolicitacoes);
    }
}
