import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StatusMessage } from '../../models/status-message.model';
import { LayoutService } from '../../services/layout.service';
import { StatusResposta } from '../../enums';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
	public statusMessage: StatusMessage;
	public defaultMessage: string = 'Sistema indisponível, tente novamente mais tarde.';

	constructor(
		private layoutService: LayoutService,
		private router: Router,
		private userService: UserService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((evt: any) => {
				// Exibe mensagem de sucesso apenas em request com mensagem customizada inserida
				if (evt instanceof HttpResponse && evt.status == 200 && evt.statusText !== 'OK') {
					this.statusMessage = new StatusMessage({
						icone: 'done',
						mensagemUsuario: evt.statusText,
						statusResposta: StatusResposta.SUCESSO,
					});

					this.layoutService.statusMessageEmit(this.statusMessage);
				}
			}),
			catchError((err: HttpErrorResponse) => {
				const error = err.error;

				this.statusMessage = new StatusMessage({
					icone: 'error_outline',
					statusResposta: StatusResposta.ERRO,
				});

				switch (err.status) {
					case 400:
						this.statusMessage.mensagemUsuario = error.mensagemUsuario;
						break;
					case 403:
						this.statusMessage.mensagemUsuario =
							'Usuário não logado. Favor inserir seus dados de login no sistema.';
						this.userService.logout();
						this.router.navigate(['/auth']);
						break;
					case 0:
						this.statusMessage.mensagemUsuario = this.defaultMessage;
						break;
					default:
						this.statusMessage.mensagemUsuario =
							error.mensagemUsuario || error[0].errorMessage || this.defaultMessage;
						break;
				}

				this.layoutService.statusMessageEmit(this.statusMessage);
				this.layoutService.carregandoEmit(false);
				return throwError(error);
			})
		);
	}
}
