export class UsuarioLogado {
	cpf: number;
	nome: string;
}

export enum TipoEventoSCDigital {
	NAVEGACAO = 1,
	ALTERACAO_FONTE = 2,
	NOVA_SENHA = 3,
	CONFIRMACAO_ALTERACAO_EMAIL = 4,
	AUTENTICACAO = 5,
	LOGOUT = 6,
}

export enum ReservaTeatroEnum {
	ADEMIR_ROSA = 1,
	ALVARO_CARVALHO = 2,
	PEDRO_IVO = 3,
}

export class EventoSCDigital {
	tipo: TipoEventoSCDigital;
	titulo?: string;
	idServico?: number;
}

export class EventoNavegacaoSCDigital extends EventoSCDigital {
	containerVisivel: boolean;
	urlDestino: string;
	urlOrigem?: string;

	constructor() {
		super();
		this.tipo = TipoEventoSCDigital.NAVEGACAO;
	}
}

export class EventoAlteracaoFonteSCDigital extends EventoSCDigital {
	fontSize: number;

	constructor() {
		super();
		this.tipo = TipoEventoSCDigital.ALTERACAO_FONTE;
	}
}

export class EventoNovaSenhaSCDigital extends EventoSCDigital {
	token: string;
	urlDestino: string;
	containerVisivel: boolean;

	constructor(init?: Partial<EventoNovaSenhaSCDigital>) {
		super();
		Object.assign(this, init);
		this.tipo = TipoEventoSCDigital.NOVA_SENHA;
	}
}

export class EventoConfirmacaoAlteracaoEmailSCDigital extends EventoSCDigital {
	token: string;
	urlDestino: string;
	containerVisivel: boolean;

	constructor(init?: Partial<EventoConfirmacaoAlteracaoEmailSCDigital>) {
		super();
		Object.assign(this, init);
		this.tipo = TipoEventoSCDigital.CONFIRMACAO_ALTERACAO_EMAIL;
	}
}

export class EventoAutenticacaoSCDigital extends EventoSCDigital {
	containerVisivel: boolean;
	usuario: string;

	constructor(init?: Partial<EventoAutenticacaoSCDigital>) {
		super();
		Object.assign(this, init);
		this.tipo = TipoEventoSCDigital.AUTENTICACAO;
	}
}

export class EventoLogoutSCDigital extends EventoSCDigital {
	constructor(init?: Partial<EventoAutenticacaoSCDigital>) {
		super();
		Object.assign(this, init);
		this.tipo = TipoEventoSCDigital.LOGOUT;
	}
}

export class TokenSCDigital {
	nome: string;
	token: string;
}
