import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoutComponent } from './autenticacao/logout/logout.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { RoleNotFoundComponent } from './core/components/role-not-found/role-not-found.component';
import { StatusMessageComponent } from './core/components/status-message/status-message.component';
import { HttpResponseInterceptor } from './core/helpers/http-response.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { GovBrComponent, IdentityServerService } from './govbr';
import { CustomPreloadingStrategy } from './services/custom-preloading-strategy.service';

registerLocaleData(ptBr);

@NgModule({
	declarations: [
		AppComponent,
		StatusMessageComponent,
		NotFoundComponent,
		GovBrComponent,
		LogoutComponent,
		RoleNotFoundComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FlexLayoutModule,

		MatSnackBarModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		// { provide: ErrorHandler, useClass: GlobalErrorHandler },
		CustomPreloadingStrategy,
		IdentityServerService,
		CookieService,
		// {
		// 	provide: ErrorHandler,
		// 	useValue: Sentry.createErrorHandler({
		// 		showDialog: false,
		// 	}),
		// },
		// {
		// 	provide: Sentry.TraceService,
		// 	deps: [Router],
		// },
		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: () => () => {},
		// 	deps: [Sentry.TraceService],
		// 	multi: true,
		// },
	],
	bootstrap: [AppComponent],
	entryComponents: [StatusMessageComponent],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class AppModule {}
