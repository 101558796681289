<div *ngIf="carregando" class="carregando" [class.pronto]="!carregando">
	<div role="alert" aria-label="Carregando" class="carregando-overlay"></div>
	<div class="carregando-spinner"></div>
</div>
<div class="scdigital-control-bar">
	<div class="scdigital-title-wrapper">
		<div class="scdigital-service-title" tabindex="0" role="presentation">{{ tituloPagina }}</div>
	</div>
	<div class="scdigital-actions">
		<button
			mat-raised-button
			*ngIf="exibirVoltar"
			type="button"
			(click)="voltar()"
			class="voltar"
			aria-label="Voltar"
		>
			voltar
		</button>
		<button
			*ngIf="urlSolicitacoes"
			type="button"
			(click)="atualizaSolicitacoes()"
			class="action"
			aria-label="Atualizar solicitações"
		>
			<mat-icon class="atualizar-solicitacoes">refresh</mat-icon>
		</button>
		<button
			type="button"
			class="action"
			(click)="closeContainer()"
			aria-label="Fechar SC Digital"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
</div>
<router-outlet></router-outlet>
