import { Injectable } from '@angular/core';
import { TokenSCDigital } from 'sc-digital-shared-types/types';
import * as jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { NiveConfiabilidadeGovBrEnum } from '../enums';

const TOKEN_KEY = 'token';
const LOGGED_USER = 'usuarioLogado';

@Injectable({ providedIn: 'root'})
export class TokenService {

    constructor(private cookieService: CookieService) {}

    hasToken(): boolean {
        return !!this.getToken();
    }

    setIdTokenOAuth(idToken: string) {
        localStorage.setItem('id_token', idToken);
    }

    removeIdTokenOAuth() {
        localStorage.removeItem('id_token');
    }

    getIdTokenOAuth() {
        return localStorage.getItem('id_token');
    }

    setToken(tokenSCDigital: TokenSCDigital): void {
        this.cookieService.set(TOKEN_KEY, tokenSCDigital.token);
        localStorage.setItem(TOKEN_KEY, tokenSCDigital.token);
        if (tokenSCDigital.nome) {
            this.cookieService.set(LOGGED_USER, tokenSCDigital.nome);
            localStorage.setItem(LOGGED_USER, tokenSCDigital.nome);
        }
    }

    getToken(): string {
        let token = this.cookieService.get(TOKEN_KEY);
        if (token == '') {
            token = localStorage.getItem(TOKEN_KEY);
        }

        return token;
    }

    getDecodedToken(): any {
        return jwt_decode(this.getToken());
    }

    removeToken(): void {
        this.cookieService.delete(TOKEN_KEY);
        this.cookieService.delete(LOGGED_USER);

        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(LOGGED_USER);
    }
    usuarioTemNivelConfiabildadeGovBrOuro(): boolean {
        let token = this.getDecodedToken();
        
        const niveis = token.infosUsuario.confiabilidadesGovBr.niveis;
        return !!niveis && niveis.filter(el => el.id >= NiveConfiabilidadeGovBrEnum.OURO.id).length > 0;
    }

    usuarioTemNivelConfiabildadeGovBrPrata(): boolean {
        let token = this.getDecodedToken();

        const niveis = token.infosUsuario.confiabilidadesGovBr.niveis;
        return !!niveis && niveis.filter(el => el.id >= NiveConfiabilidadeGovBrEnum.PRATA.id).length > 0;
    }

    usuarioTemNivelConfiabilidadeGovBrRequerido(nivelRequerido): boolean {
        switch(nivelRequerido) {
            case NiveConfiabilidadeGovBrEnum.OURO: {
                return this.usuarioTemNivelConfiabildadeGovBrOuro();
            }
            case NiveConfiabilidadeGovBrEnum.PRATA: {
                return this.usuarioTemNivelConfiabildadeGovBrPrata();
            }
        }
    }

    getNivelConfiabilidadeGovBrUsuario() {
        if(this.usuarioTemNivelConfiabildadeGovBrOuro()) {
            return NiveConfiabilidadeGovBrEnum.OURO;
        } else if(this.usuarioTemNivelConfiabildadeGovBrPrata()) {
            return NiveConfiabilidadeGovBrEnum.PRATA
        } else {
            return NiveConfiabilidadeGovBrEnum.BRONZE;
        }
    }
}