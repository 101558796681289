import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {
	EventoNavegacaoSCDigital,
	EventoAutenticacaoSCDigital,
} from 'sc-digital-shared-types/types';
import { StatusMessage } from './models/status-message.model';
import { LayoutService } from './services/layout.service';
import { RequisicoesService } from './services/requisicoes.service';
import { StatusMessageService } from './shared/services/status-message.service';
import { UserService } from './services/user.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public carregando: boolean = false;
	public tituloPagina: string;
	public urlSolicitacoes: boolean = false;
	public exibirVoltar: boolean = false;
	public urlsVoltarHabilitado: string[] = [
		'/formularios/executarPendencia',
		'/formularios/cadatrar-estabelecimento',
		'/auth/configuracao-login',
	];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private layoutService: LayoutService,
		private requisicoesService: RequisicoesService,
		private statusMessageService: StatusMessageService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.layoutService
			.carregandoEvent()
			.subscribe((carregando) => (this.carregando = carregando));

		this.layoutService
			.statusMessageEvent()
			.subscribe((status: StatusMessage) =>
				this.statusMessageService.openStatusMessage(status)
			);

		this.layoutService.tituloPagina$.subscribe((titulo) => (this.tituloPagina = titulo));

		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((data: NavigationEnd) => {
				if (data instanceof NavigationEnd) {
					this.urlSolicitacoes = data.url.includes('/solicitacoes');
					const urlFormularios = data.url.includes('/formularios') 
						|| data.url.includes('/apps/sc-digital-formularios');

					const dadosRota = this.activatedRoute && this.activatedRoute.root.firstChild
						? this.activatedRoute.root.firstChild.snapshot.data
						: null;

					if (urlFormularios && this.tituloPagina != '') {
						this.layoutService.setTituloPagina(this.tituloPagina);
					} else if (dadosRota.titulo) {
						this.layoutService.setTituloPagina(dadosRota.titulo);
					} else if (!dadosRota.manterTituloPagina) {
						this.layoutService.setTituloPagina('Serviços ao Cidadão');
					}

					this.exibirVoltar = !!(
						data.url && this.urlsVoltarHabilitado.includes(data.url)
					);
				}
			});
	}

	closeContainer() {
		const evento: EventoNavegacaoSCDigital = new EventoNavegacaoSCDigital();
		evento.containerVisivel = false;
		this.requisicoesService.enviaEventoSCDigital(evento);
	}

	atualizaSolicitacoes() {
		this.layoutService.setAtualizaSolicitacoes(true);
	}

	voltar() {
		window.history.back();
		return false;
	}

	ngAfterViewInit() {
		this.requisicoesService.enviaEventoSCDigital(
			new EventoAutenticacaoSCDigital({
				usuario: this.userService.getNomeUsuarioLogado(),
				containerVisivel: true,
			})
		);
	}
}
