import { StatusResposta } from '../enums';

export class StatusMessage {

    public constructor(init?: Partial<StatusMessage>) {
        Object.assign(this, init);
    }

    statusResposta: StatusResposta;
    icone: string;
    mensagemUsuario: string;
}