import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EventoAlteracaoFonteSCDigital, EventoNavegacaoSCDigital, EventoSCDigital, TipoEventoSCDigital } from 'sc-digital-shared-types/types';
import { environment } from 'src/environments/environment';
import { LayoutService } from './layout.service';

@Injectable({ providedIn: 'root' })
export class RequisicoesService {

    private renderer: Renderer2;

    constructor(
        private rendererFactory2: RendererFactory2,
        private router: Router,
        private layoutService: LayoutService,
        private cookieService: CookieService
    ) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);
        this.renderer.listen('window', 'message', (evento: MessageEvent) => {
            this.tratarEvento(evento.data as EventoSCDigital);
        });
    }

    private tratarEvento(evento: EventoSCDigital) {
        switch (evento.tipo) {
            case TipoEventoSCDigital.NAVEGACAO:
                const navegacao = evento as EventoNavegacaoSCDigital;
                navegacao.urlOrigem && this.setReturnUrlParent(navegacao.urlOrigem);
                if (navegacao.idServico) {
                    localStorage.setItem('id_servico', navegacao.idServico.toString());                    
                }

                this.layoutService.setTituloPagina(navegacao.titulo);
                this.router.navigateByUrl(navegacao.urlDestino);
                break;
            case TipoEventoSCDigital.ALTERACAO_FONTE:
                const alteracaoFonte = evento as EventoAlteracaoFonteSCDigital;
                this.layoutService.setTamanhoFonte(alteracaoFonte.fontSize);
                break;
            case TipoEventoSCDigital.LOGOUT:
                this.logout();
                break;
        }
    }

    submitToPopup(form: any) {
        const refLogout = window.open('', 'form-target', 'width=600, height=400, top=0, left=0');
        form.target = 'form-target';
        form.submit();
        setTimeout(() => refLogout && refLogout.close(), 3000);
    }

    logout() {
        const idTokenWso2 = localStorage.getItem('id_token');

        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', environment.urlIdentityServer + '/oidc/logout');

        const field1 = document.createElement('input');
        field1.setAttribute('type', 'hidden');
        field1.setAttribute('name', 'id_token_hint');
        field1.setAttribute('value', idTokenWso2);
        form.appendChild(field1);

        const field2 = document.createElement('input');
        field2.setAttribute('type', 'hidden');
        field2.setAttribute('name', 'post_logout_redirect_uri');
        field2.setAttribute('value', environment.redirectLogoutUri + environment.urlIdentityServerRetornoLogout);
        form.appendChild(field2);

        const field3 = document.createElement('input');
        field3.setAttribute('type', 'hidden');
        field3.setAttribute('name', 'state');
        field3.setAttribute('value', 'state-logout');
        form.appendChild(field3);

        document.body.appendChild(form);

        this.submitToPopup(form);
    }

    enviaEventoSCDigital(evento: EventoSCDigital): void {
        parent.postMessage(evento, '*');
    }

    private setReturnUrlParent(url: string): void {
        this.cookieService.set('returnUrlParent', url);
        localStorage.setItem('returnUrlParent', url);
    }

    getReturnUrlParent(): string {
        let returnUrl = this.cookieService.get('returnUrlParent');
        if (returnUrl == '') {
            returnUrl = localStorage.getItem('returnUrlParent')
        }
        return returnUrl;
    }

    removeReturnUrlParent(): void {
        this.cookieService.delete('returnUrlParent');
        localStorage.removeItem('returnUrlParent');
    }
}
